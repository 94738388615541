<template>
  <div class="share">
    <div class="share-bg">
      <img class="share-img" :src="shareImg[isApp]" />
      <div class="box">
        <div class="share-box">
          <div class="left">
            <div class="img">
              <img src="../img/组 31.png" alt="" />
              <p>关注果实AI公众号</p>
            </div>
            <div class="lianxi">
              <p>联系我们</p>
              <p>13067786061</p>
              <p>周一至周日 9:00-24:00</p>
              <p>长沙市芙蓉区古汉国际广场1栋11楼</p>
            </div>
          </div>
          <div class="right">
            <p>湖南果实智能科技有限公司</p>
            <p>
              为教培行业提供信息化、电商化、互联网化的整体解决方案，真正做到让校区生源和利润10倍增长！
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="share-fiexd">
      <div class="share-form-bg">
        <div class="share-form">
          <el-row :gutter="24">
            <el-col :span="formFormat[isApp].name.span" class="mb15">
              <el-input
                v-model="form.name"
                class="share-form-input"
                placeholder="请输入您的姓名"
              ></el-input>
            </el-col>
            <el-col :span="formFormat[isApp].phone.span" class="mb15">
              <el-input
                v-model="form.phone"
                class="share-form-input"
                placeholder="请输入您的手机号"
              ></el-input>
            </el-col>
            <el-col :span="formFormat[isApp].code.span" class="mb15">
              <el-input
                v-model="form.code"
                class="share-form-input"
                placeholder="输入验证码"
              ></el-input>
            </el-col>
            <el-col :span="formFormat[isApp].codeBtn.span" class="mb15 share-getCode">
              <el-button
                class="btny"
                :type="btnTxt == '获取验证码' ? 'warning' : 'info'"
                :disabled="btnTxt != '获取验证码'"
                @click="getCode"
                >{{ btnTxt }}</el-button
              >
            </el-col>
            <el-col
              :span="formFormat[isApp].sub.span"
              class="share-submit"
              :offset="formFormat[isApp].sub.offset"
            >
              <el-button type="primary" @click="submit">提交</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCode, remberRemark } from "../server/api";
import appShare from "../img/appShare.jpg";
import webShare from "../img/webShare.jpg";
import shareBottom from "../img/shareBottom.jpg";
const defaultForm = {
  phone: "",
  name: "",
  code: "",
  source: "",
};
export default {
  name: "share",
  data() {
    return {
      shareImg: {
        1: appShare,
        2: webShare,
      },
      shareBottom,
      form: Object.assign({}, defaultForm),
      btnTxt: "获取验证码",
      isApp: 1,
      formFormat: {
        1: {
          name: { span: 24, offset: 0 },
          phone: { span: 24, offset: 0 },
          code: { span: 12, offset: 0 },
          codeBtn: { span: 12, offset: 0 },
          sub: { span: 24, offset: 0 },
        },
        2: {
          name: { span: 5, offset: 0 },
          phone: { span: 5, offset: 0 },
          code: { span: 5, offset: 0 },
          codeBtn: { span: 3, offset: 0 },
          sub: { span: 3, offset: 3 },
        },
      },
    };
  },
  created() {
    this.getWid();
    this.getWindowResize();
  },
  methods: {
    getWindowResize() {
      let that = this;
      window.onresize = function() {
        that.getWid();
      };
    },
    getWid() {
      var offsetWid = document.documentElement.clientWidth;
      if (offsetWid <= 800) {
        this.form.source = "share_app";
        this.isApp = 1;
      } else {
        this.isApp = 2;
        this.form.source = "share_web";
      }
    },
    submit() {
      let btn = this.checkForm(1);

      if (!btn) {
        return;
      }
      let innerParam = {
        source: this.form.source,
        phone: this.form.phone,
        name: this.form.name,
        code: this.form.code,
      };
      remberRemark(innerParam).then((res) => {
        if (res.code == 200) {
          this.form = Object.assign({}, defaultForm);
          this.$message({ message: "预约成功", type: "success" });
        } else {
          this.$message({ message: res.message, type: "error" });
        }
      });
    },
    timeComputer() {
      let time = 60;
      let that = this;
      this.btnTxt = time + "s";
      this.time2 = setInterval(function() {
        time--;
        if (time < 0) {
          clearInterval(that.time2);
          that.btnTxt = "获取验证码";
          return;
        }
        that.btnTxt = time + "s";
      }, 1000);
    },
    getCode() {
      let btn = this.checkForm(2);
      if (btn && this.btnTxt == "获取验证码") {
        this.timeComputer();
        let param = {
          openId: "gsApp",
          phone: this.form.phone,
        };
        getCode(param).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "发送成功", type: "success" });
          } else {
            this.$message({ message: res.message, type: "error" });
            clearInterval(this.time2);
            this.btnTxt = "获取验证码";
          }
        });
      }
    },
    isPoneAvailable(num) {
      if (!String(num).trim()) {
        this.$message({ message: "请输入手机号", type: "error" });
        return false;
      }
      var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (!myreg.test(String(num))) {
        this.$message({ message: "请输入正确手机号", type: "error" });
        return false;
      } else {
        return true;
      }
    },
    isUserName(name) {
      if (!String(name).trim()) {
        this.$message({ message: "请输入姓名", type: "error" });
        return false;
      }
      var myreg1 = /^[a-zA-Z ]{1,20}$/;
      var myreg2 = /^[\u4e00-\u9fa5]{1,10}$/;
      if (myreg1.test(String(name)) || myreg2.test(String(name))) {
        return true;
      } else {
        this.$message({ message: "请输入正确姓名", type: "error" });
        return false;
      }
    },

    isCode(code) {
      if (!String(code).trim()) {
        this.$message({ message: "请输入验证码", type: "error" });
        return false;
      } else {
        return true;
      }
    },

    checkForm(type) {
      //type为1提交 2 获取验证码
      if (type == 2) {
        if (!this.isPoneAvailable(this.form.phone)) {
          return false;
        } else {
          return true;
        }
      } else {
        if (!this.isUserName(this.form.name)) {
          return false;
        } else if (!this.isPoneAvailable(this.form.phone)) {
          return false;
        } else if (!this.isCode(this.form.code)) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
};
</script>

<style scoped>
.share {
  width: 100%;
}
.share-bg {
  margin-bottom: 140px;
}
.share-img {
  width: 100%;
}

/* 底部固定 */
.share-fiexd {
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 199px;
  background-image: url("../img/组 23 拷贝.png");
  background-repeat: no-repeat;
  background-position: 32%;
}
.share-form {
  position: absolute;
  left: 32%;
  top: 140px;
}
/* 底部信息 */
.box {
  background: url("../img/shareBottomBg.jpg");
}
.share-box {
  margin: -4px auto;
  color: #ffffff;
  display: flex;
  width: 80%;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  padding: 26px 0px 20px 0px;
}
.share-box .left {
  display: flex;
  flex-wrap: nowrap;
}
.share-box .left .img p {
  margin: 0;
  font-size: 14px;
  color: #ccc;
  text-align: center;
}
.share-box .left .img {
  margin-right: 40px;
}
.share-box .left .lianxi p:first-child,
.share-box .left .lianxi p:nth-child(2) {
  font-size: 20px;
}
.share-box .left .lianxi p:nth-child(3),
.share-box .left .lianxi p:last-child {
  font-size: 14px;
  color: #ccc;
}
.share-box .left .lianxi p:first-child {
  margin: 0;
  margin-bottom: 24px;
}

.share-box .left .lianxi p:nth-child(2),
.share-box .left .lianxi p:nth-child(3),
.share-box .left .lianxi p:last-child {
  margin: 0;
  margin-bottom: 11px;
}
.share-box .right {
  width: 500px;
}
.share-box .right p:first-child {
  font-size: 20px;
}
.share-box .right p:last-child {
  font-size: 16px;
  line-height: 28px;
}

/deep/ .share-form-input .el-input__inner {
  background-color: #031f48 !important;
  border: 1px solid #9aa5b6;
  color: #fff;
}
/deep/ .share-getCode .el-button--warning {
  background-color: #ff9611;
  border-color: #ff9611;
}
/deep/ .share-submit .el-button--primary {
  background-color: #1472ff;
  border-color: #1472ff;
}
</style>
